import * as BABYLON from "babylonjs";
import {Color4} from "babylonjs";
import 'babylonjs-loaders';
import {ILoadingScreen} from "babylonjs";

//Loading Screen Class
class CustomLoadingScreen implements ILoadingScreen{

    public loadingUIBackgroundColor = "white";
    constructor(public loadingUIText:"Quaint"){}
    public displayLoadingUI(){

    }

    public hideLoadingUI(){

    }

}

export class App {

    private readonly canvas: any = document.getElementById("mainCanvas");
    private readonly engine: BABYLON.Engine = new BABYLON.Engine(this.canvas, true);
    private scene: BABYLON.Scene = new  BABYLON.Scene(this.engine);

    private loadingScreen: any = new CustomLoadingScreen("Quaint");

    private createScene() {

        this.engine.loadingScreen = this.loadingScreen;
        this.engine.displayLoadingUI();
        this.scene = new BABYLON.Scene(this.engine);
        const origin = new BABYLON.Vector3(0, 0.42, 0);

        // Add a camera to the scene and attach it to the canvas
       let camera = new BABYLON.ArcRotateCamera("Camera", 3,3,3, origin, this.scene);
       camera.attachControl(this.canvas, true);


        // Add lights to the scene
        let light1 = new BABYLON.HemisphericLight("light1", new BABYLON.Vector3(1, 1, 0), this.scene);
        light1.diffuse = new BABYLON.Color3(1, 1, 0.85);
        light1.intensity = 0.5;

        //HDR Environment
        let HDR = this.scene.createDefaultEnvironment();
        HDR.skybox.visibility=0;
        HDR.ground.visibility=0;


        //Materials
        let skin = new BABYLON.PBRMetallicRoughnessMaterial("skin", this.scene);
        skin.baseColor = new BABYLON.Color3(0.62 , 0.4, 0.4);
        skin.metallic=0;
        skin.roughness=0.4;
        skin.backFaceCulling=false;


        //Change Background Color
        this.scene.clearColor = new Color4(1, 0.7, 0.3, 0);

        //Append Model in the scene
        var scene = this.scene;
       // var engine = this.engine;
        BABYLON.SceneLoader.Append("./assets/models/","quaint001.glb",this.scene,function (newScene) {

            scene= newScene;
           // engine = newEngine;
            camera.setPosition(new BABYLON.Vector3(-1.5,1,2));
            camera.lowerRadiusLimit= 1.8;
            camera.upperRadiusLimit= 5;
            camera.panningInertia=0.4;
            camera.wheelDeltaPercentage = 0.025;
            camera.pinchDeltaPercentage = 0.025;


            //Meshes
            let teeth = scene.getMeshByID("21");
            let jaw1 = scene.getMeshByID("Kiefer_heil");
            let jaw2 = scene.getMeshByID("Kiefer_defekt");

            //Materials
            jaw1.material= skin;
            jaw2.material= skin;



            // Buttons
            let visButton = document.getElementById("visButton");
            let jawButton = document.getElementById("jawButton");
            let animationButton = document.getElementById("animationButton");


            //Scene Actions
            visButton.onclick = function () {
                teeth.setEnabled(!teeth.isEnabled());

            };

            jawButton.onclick = function (){
                jaw1.setEnabled (!jaw1.isEnabled());

            };

            //engine.hideLoadingUI();



           /*teeth.actionManager = new BABYLON.ActionManager(scene);
           teeth.actionManager.registerAction(
               new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.NothingTrigger, function () {

                   teeth.visibility = 0;

               })
           );*/

        });




    }

    public Setup(){
        this.createScene(); //Call the createScene function
                // Register a render loop to repeatedly render the scene
        this.engine.runRenderLoop(()=> {
            this.scene.render();
        });

        

    }
    
    public Resize(){

        let engine = this.engine;
        window.addEventListener("resize", function () {

            engine.resize();

        });
    }


}

