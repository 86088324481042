import {App} from './App';

const app = new App();
app.Setup();
app.Resize();






